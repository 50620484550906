/**
 * トップページに関する処理
 * （昔のLPバージョン）
 */

// import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

// npmでsplideを読み込むとロゴスライダーがうまくいかないから、splideは<head>で直接読み込んでる
// import Splide from '@splidejs/splide';
// import { Intersection } from '@splidejs/splide-extension-intersection';

export default class Index_old {
  constructor(_parm) {
    this.pageParent = document.querySelector(_parm.parent) || false;
    if (!this.pageParent) return;
  }

  /**
   * ロゴループアニメーションのスライダー
   */
  mediaSlider() {
    const target = '#js-index-media-slider';
    const options = {
      perPage: 8,
      gap: '54px',
      type: 'loop',
      arrows: false,
      drag: false,
      flickPower: 300,
      pagination: false,
      autoScroll: {
        speed: 0.5,
        pauseOnHover: false,
        pauseOnFocus: false,
      },
      breakpoints: {
        1440: {
          perPage: 6,
        },
        900: {
          perPage: 4,
          gap: '40px',
        },
        640: {
          perPage: 3,
          gap: '24px',
        }
      },
    }
    const mySplide = new Splide(target, options);
    mySplide.mount(window.splide.Extensions);
  }

  /**
   * 受講生の声のスライダー
   */
  voiceSlider() {
    const swiper = new Swiper("#js-index-voice-slider", {
      slidesPerView: 1,
      spaceBetween: 16,
      loop: true,
      loopAdditionalSlides: 1,
      speed: 1000,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      grabCursor: true,
      watchSlidesProgress: true,
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '#js-index-voice-slider .swiper-button-next',
        prevEl: '#js-index-voice-slider .swiper-button-prev',
      },
      breakpoints: {
        767: {
          slidesPerView: 2,
        },
        900: {
          slidesPerView: 3,
        }
      },
      // slidesPerView: 'auto',
      // // centeredSlides: true,
      // spaceBetween: 50,
      // // initialSlide: 0,
      // speed: 1000,
      // loop: true,
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // },
      // pagination: {
      //   el: ".swiper-pagination",
      //   clickable: true,
      // },
      // modules: [Navigation, Pagination, Autoplay],
      // breakpoints: {
      //   769: {
      //     // slidesPerView: 2,
      //     // initialSlide: 1,
      //   },
      //   // 1081: {
      //   //   slidesPerView: 3,
      //   //   initialSlide: 1,
      //   // },
      // },


      //   slidesPerView: 1,
      //   spaceBetween: 16,
      //   loop: true,
      //   loopAdditionalSlides: 1,
      //   speed: 1000,
      //   autoplay: {
      //     delay: 4000,
      //     disableOnInteraction: false,
      //   },
      //   grabCursor: true,
      //   watchSlidesProgress: true,
      //   navigation: {
      //     nextEl: ".swiper-button-next",
      //     prevEl: ".swiper-button-prev",
      //   },
      //   pagination: {
      //     el: ".swiper-pagination",
      //     clickable: true,
      //   },
      //   modules: [Navigation, Pagination, Autoplay],
      //   breakpoints: {
      //     600: {
      //       slidesPerView: 2,
      //     },
      //     1025: {
      //       slidesPerView: 4,
      //       spaceBetween: 32,
      //     }
      //   },
    });






    // const target = '#js-index-voice-slider';
    // const options = {
    //   mediaQuery: 'min',
    //   perPage: 1,
    //   perMove: 1,
    //   gap: '0px',
    //   // type: 'loop',
    //   autoplay: true,
    //   width: '100%',
    //   interval: 5000,
    //   speed: 1000,
    //   pagination: true,
    //   breakpoints: {
    //     767: {
    //       perPage: 3,
    //     },
    //   },
    // }
    // const mySplide = new Splide(target, options);
    // mySplide.mount();
  }

  scrollShowContactModal() {
    const elmScrollContactModal = document.querySelector('#js-scroll-contact-modal');
    const elmCloseBtn = document.querySelector('#js-scroll-contact-modal-close-btn');
    if (!elmScrollContactModal) return;
    const elmTrigger = document.querySelector('#sec-step');
    // IntersectionObserverの作成
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        // ターゲット要素が表示領域に入ったらモーダルを表示
        if (entry.isIntersecting) {
          elmScrollContactModal.setAttribute('data-scroll-active', 'true');
          observer.unobserve(entry.target);  // 必要なら、さらにスクロールしてもモーダルが表示されないようにする
        }
      });
    });
    // ファーストビューを監視（ファーストビューをスクロールし終わったら、ヘッダー背景色を出現させる）
    observer.observe(elmTrigger);
    // 閉じるボタンクリック
    elmCloseBtn.addEventListener('click', (_ev) => {
      elmScrollContactModal.setAttribute('data-scroll-active', 'false');
    });
  }

  scrollAnima() {
    // 監視する要素
    const arrayElmsTarget = [...document.querySelectorAll('[data-scroll-trigger]')];
    // オプション
    const options = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    };
    // 監視開始
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(_entry => {
        if (_entry.isIntersecting) {
          _entry.target.classList.add('is-scrolled');
        } else {
          // this.elmHeader.setAttribute('data-js-scroll', 'true');
        }
      });
    });
    arrayElmsTarget.forEach(_elmTarget => {
      observer.observe(_elmTarget, options);
    });
  }

  init() {
    if (!this.pageParent) return;
    this.mediaSlider();
    this.voiceSlider();
    this.scrollShowContactModal();
    this.scrollAnima();
  }
}
