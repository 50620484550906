/**
 * FAQのアコーディオン
 * @see https://ics.media/entry/220901/
 */

import gsap from 'gsap';

export default class Accordion {
  constructor() {
    this.arrayElmsAccordionItem = [...document.querySelectorAll('[data-accordion-item]')];
    if (!this.arrayElmsAccordionItem.length) return;
    this.headerSelector = '[data-accordion-header]';
    this.bodySelector = '[data-accordion-body]';
    this.openedClass = 'is-opened';
  }

  openAnima(elmBody) {
    return gsap.fromTo(
      elmBody,
      {
        height: 0,
        opacity: 0,
      },
      {
        height: 'auto',
        opacity: 1,
        duration: 0.4,
        ease: 'power4.out',
        overwrite: true,
      }
    );
  }

  closeAnima(elmBody, elmAccordionItem) {
    return gsap.to(elmBody, {
      height: 0,
      opacity: 0,
      duration: 0.4,
      ease: 'power4.out',
      overwrite: true,
      onComplete: () => {
        elmAccordionItem.removeAttribute('open');
      },
    });
  }

  addEvent() {
    this.arrayElmsAccordionItem.forEach((elmAccordionItem) => {
      const elmHeader = elmAccordionItem.querySelector(this.headerSelector);
      const elmBody = elmAccordionItem.querySelector(this.bodySelector);
      elmHeader.addEventListener('click', (event) => {
        event.preventDefault();
        if (elmAccordionItem.classList.contains(this.openedClass)) {
          elmAccordionItem.classList.toggle(this.openedClass);
          this.closeAnima(elmBody, elmAccordionItem).restart();
        } else {
          elmAccordionItem.classList.toggle(this.openedClass);
          elmAccordionItem.setAttribute('open', 'true');
          this.openAnima(elmBody).restart();
        }
      });
    });
  }

  init() {
    if (!this.arrayElmsAccordionItem.length) return;
    this.addEvent();
  }
}
