// Library
import MicroModal from 'micromodal';

// Funtion
import adjustViewport from './function/adjustViewport'

// Module
import Accordion from './module/Accordion';
import FixedBottomBtn from './module/FixedBottomBtn';
import SmoothScroll from './module/SmoothScroll';
import TwitterReviewSlider from './module/TwitterReviewSlider';

// Page
// import Index from './page/Index';
import Index from './page/Index_old';

const init = () => {

  (() => {
    MicroModal.init({
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      openClass: 'is-active',
    });
    // モーダル内の全てのリンクを取得
    const modalLinks = document.querySelectorAll('#js-drawer-menu a');
    modalLinks.forEach(link => {
      link.addEventListener('click', () => {
        MicroModal.close();
      });
    });
  })();

  (() => {
    const smoothScroll = new SmoothScroll({
      targets: 'a[href^="#"]',
      fixHeader: '#js-header',
    });
    smoothScroll.init();
  })();

  (() => {
    const accordion = new Accordion();
    accordion.init();
  })();

  (() => {
    const twitterReviewSlider = new TwitterReviewSlider();
    twitterReviewSlider.init();
  })();

  (() => {
    const fixedBottomBtn = new FixedBottomBtn();
    fixedBottomBtn.init();
  })();

  (() => {
    const index = new Index({
      parent: '#js-page-index',
    });
    index.init();
  })();

};

window.addEventListener('DOMContentLoaded', () => {
  adjustViewport();
  init();
});