/**
 * 画面下部固定ボタン
 */

export default class FixedBottomBtn {
  constructor(_parm) {
  }

  init() {
    const elmFixedBottomBtn = document.querySelector('#js-fixed-bottom-btn');
    if (!elmFixedBottomBtn) return;
    const elmTrigger = document.querySelector('#js-fixed-bottom-btn-scroll-trigger');
    // IntersectionObserverの作成
    const observer = new IntersectionObserver((entries) => {
      for (const e of entries) {
        // 監視対象が画面内にあるとき
        if (e.isIntersecting) {
          elmFixedBottomBtn.setAttribute('data-scroll-active', 'false');
          // 監視対象が画面外にあるとき
        } else {
          elmFixedBottomBtn.setAttribute('data-scroll-active', 'true');
        }
      }
    });
    // ファーストビューを監視（ファーストビューをスクロールし終わったら、ヘッダー背景色を出現させる）
    observer.observe(elmTrigger);
  }
}
