/**
 * Twitter口コミのスライダー
 */

export default class TwitterReviewSlider {
  constructor(_parm) {
    this.pageParent = document.querySelector('#js-curriculum-review-slider') || false;
    if (!this.pageParent) return;
  }

  init() {
    if (!this.pageParent) return;
    const target = '#js-curriculum-review-slider';
    const options = {
      perPage: 5,
      gap: '30px',
      type: 'loop',
      arrows: false,
      drag: 'free',
      // drag: false,
      flickPower: 300,
      pagination: false,
      autoScroll: {
        speed: 0.5,
        pauseOnHover: false,
        pauseOnFocus: false,
      },
      breakpoints: {
        1440: {
          perPage: 4,
        },
        900: {
          perPage: 3,
        },
        640: {
          perPage: 2,
        },
        500: {
          perPage: 2,
          gap: '1px',
        }
      },
    }
    const mySplide = new Splide(target, options);
    mySplide.mount(window.splide.Extensions);
  }
}
